<template>
  <div class="container">
    <span class="text-muted">Redirecting...</span>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "callback",
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    var baseURI = this.$baseurl + "/auth/user";
    var config = {
      Authorization: "Bearer " + this.$route.query.access_token,
    };
    this.$http
      .get(baseURI, {
        headers: config,
      })
      .then((result) => {
        //this.setBeats(result.data)
        console.log(result.data);
        if (result.data.token) {
          this.setUpUserAction(result.data).then(() => {
            window.location.href = this.$route.query.redirect_uri || "/";
          });
        }
      });
  },
  methods: {
    ...mapActions(["setUpUserAction"]),
  },
};
</script>

<style>
</style>
